import React from 'react';
import logo from './logo.svg';
import './Home.css';

function Home() {
  return (
    <div className="Home">
      <header className="Home-header">
        <p className="Home-suspens-content">

          Site en construction… Revenez bientôt !
        </p>
      </header>
    </div>
  );
}

export default Home;
